<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊岡山必備的景點套票，精選岡山多個人氣設施與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同設施只可以使用一次，相同設施不可再入場<br>
          ●票劵使用效期為購買日起<span>90天內</span><br>
           EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
          ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
          EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
          ●有效期間內可任<span>選3項設施</span><br>
          ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
          ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
           EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
          ●無使用完畢不能兌現<br>
          ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokayama/zh_tw/havefun_title-tc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 10,
          img: require('@/assets/images/hfokayama/10_TC.png'),
          title: ['岡山城天守閣 入場劵+天守閣1樓「烏城咖啡」季節聖代'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒700-0823岡山市北區丸の內2-3-1岡山市北區丸之內2-3-1',
                '入場劵: ',
                '請將電子門票出示給岡山城天守閣售票處的工作人員兌換實體入場劵',
                '「烏城咖啡」季節聖代:',
                '請向天守閣1樓「烏城咖啡」的工作人員出示電子票劵和入場券(實體)兌換季節聖代。',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['岡山城:'],noColor:true },
                { cid: 2, text: ['https://okayama-castle.jp/home-tw/'] },
                { cid: 3, text: ['岡山城天守閣1樓「烏城咖啡」:'],noColor:true },
                { cid: 4, text: ['https://okayama-castle.jp/gather-shop-tw/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※公休日：12/29～12/31'] },
                { cid: 2, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                { cid: 3, text: ['圖片僅供參考，實品請以實物為主。'] },
                ]
            }
          ]
        },
        {
          id: 1,
          img: require('@/assets/images/hfokayama/01_TC.png'),
          title: ['岡山縣夢二鄉土美術館 本館 入館劵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒703-8256 岡山縣岡山市中區浜2丁目1-32'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://yumeji-art-museum.com/hantaiji/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。 '] },
                { cid: 2, text: ['只有本館可以入場。(入場少年山莊需支付額外費用)'] },
            
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfokayama/02_TC.png'),
          title: ['岡山格蘭比亞大酒店「lumiere」1,000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒700-8515 冈山市北区站元町1番5'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.granvia-oka.co.jp/tw/restaurant/#lum'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可享受1000日元優惠券。 '] },
                { cid: 2, text: ['餐點供應時間：9:00~21:00(最後點餐20:30)，但是聖代供應時間為12:00~21:00(最後點餐20:30)'] },
                { cid: 3, text: ['水果聖代的水果因季節而不同。產品相片僅供參考。'] },
                { cid: 4, text: ['超過優惠券金額的差額由顧客自行負擔。'] },
                { cid: 5, text: ['結帳後不可取消。'] },
                { cid: 6, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokayama/03_TC.png'),
          title: ['岡山縣岡電博物館入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒703-8281岡山縣岡山市中區東山2丁目3-33'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://okayama-kido.co.jp/okadenmuseum/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。 '] },    
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokayama/04_TC.png'),
          title: ['café Antena 1,000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒700-0812　岡山市北區出石町1-8-23'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://okayama-parfait.com/parfait/264.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。  '] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。 '] },
                { cid: 4, text: ['.有可能會因為店家客滿而無法使用的狀況，請見諒。 '] },
       
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokayama/05_TC.png'),
          title: ['岡山縣倉敷美觀地區漫步優惠劵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '倉敷站前觀光介紹所(倉敷駅前観光案內所)','岡山縣倉敷市阿知1-7-2'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://okayama.visit-town.com/visittour/cp_kurashiki-machi-2023/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品銷售完畢時無替代商品。請於其他店鋪設施使用。另外，各店鋪設施有可能臨時公休。 '] },
                { cid: 2, text: ['本點券不可與其他優惠券同時或重複使用。'] },
                { cid: 3, text: ['未使用點券無法退換現金，請見諒。'] },
                { cid: 4, text: ['本點券請自行妥善保管，如有遺失恕不負責。'] },
                { cid: 5, text: ['本點券有效期間為2023/4/1 - 2024/3/31'] },
                { cid: 6, text: ['有可能無預告增減店鋪及變更商品內容。'] },
                { cid: 7, text: [' 1組（10張）1人使用。'] },
                { cid: 8, text: ['每個店鋪所需使用的點券張數不同。也有入館或體驗即需要使用10張的設施。此時若尚需使用其他設施，請購買2組以上。'] },
                { cid: 9, text: ['請客人前往設施前先確認各注意事項。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokayama/06_TC.png'),
          title: ['Betty Smith Factory Outlet 1,500日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒711-0906 岡山縣倉敷市兒島下之町5丁目2−70 BettySmith'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://betty.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1500日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokayama/07_TC.jpg'),
          title: ['牛仔褲之城兒島 巴士1日乘車券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '【兒島營業所】〒711-0913　倉敷市兒島味野3-2-10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['http://shimoden.net/rosen/kikaku/jeans.html#oneday'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['兌換1日乘車券後，無法退款。即使遺失或被盜也無法補發。'] },
                { cid: 2, text: ['因自然災害、地震等原因導致巴士延誤或旅遊設施關閉，也無法退款。'] },
                { cid: 3, text: ['如果錯過巴士而乘坐出租車等情況，也無法退款。'] },
                { cid: 4, text: ['適用路線為 Jeans Bus、「FUREAI號」、「TOKOHAI號」、「王子岳線(至王子岳登山口站)」。'] },
                { cid: 5, text: ['必須先在兒島營業所兌換1日乘車券後才能使用。 ※無法在巴士上兌換。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokayama/08_TC.jpg'),
          title: ['WASHU BLUE RESORT 風籠 溫泉泡湯券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒711-0926 岡山縣倉敷市下津井吹上303-17'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['http://www.kasago.jp.t.aah.hp.transer.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['泡湯一日遊有休息日，詳情請參考官網。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokayama/09_TC.png'),
          title: ['JR岡山站前家電量販店「Bic Camera 岡山站前店」1000日圓禮券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒700-0023 冈山县冈山市北区駅前町1-1-1 1楼综合服务臺'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.biccamera.com.t.lj.hp.transer.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['不能兌換成現金。'] },
                { cid: 2, text: ['對於盜竊、遺失或損毀，本公司不承擔任何責任。'] },
                { cid: 3, text: ['不可兌換其他的商品券、明信片、印花、香煙、其他本公司指定的商品等。'] },
                { cid: 4, text: ['各店鋪的營業時間請事先在各官方網站確認。'] },
                { cid: 5, text: ['若有價差需現場支付差額。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkayama&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okayama-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>